import { removeSession } from '../clients/pluto';
import { getCurrentUser } from '../clients/user';
import cache from '../cache';
import { getRPCClient } from '../lib/rpc';
import { removeCookie } from '../lib/cookie';
import {
  getAidDomain,
  getCurrentHostname,
  getCurrentSiteDomain,
} from '../utils/url';
import log from '../utils/log';
import { UserAttributes } from '../types';
import { navigateTo } from '../utils/location';
import { shouldUseErisForSiteDomain } from '../utils/shouldUseErisForSiteDomain';

export const loginUrl = (requestedUrl: URL | string, context = 'login_url') => {
  let url: URL;
  if (shouldUseErisForSiteDomain(getCurrentSiteDomain())) {
    url = new URL(`https://${getCurrentHostname()}/aid/login/`);
    url.searchParams.set(
      'requested_url',
      requestedUrl instanceof URL ? requestedUrl.href : requestedUrl
    );
    url.searchParams.set('context', context);
  } else {
    url = new URL(
      `https://${getAidDomain()}/aid/logg_inn/${getCurrentSiteDomain()}/`
    );
    url.searchParams.set(
      'requestedUrl',
      requestedUrl instanceof URL ? requestedUrl.href : requestedUrl
    );
    url.searchParams.set('context', context);
  }
  return url;
};

export const login = (requestedUrl: URL | string, context = 'login') => {
  const url = loginUrl(requestedUrl, context);
  navigateTo(url.href);
};

function clearCookies() {
  removeCookie('VSTOKEN');
  removeCookie('VSTOKEN2');
  removeCookie('aid.session');
  removeCookie('aid_invitation_code');
  removeCookie('aid-reload-article');
  removeCookie('daxsub');
}

export const logout = async () =>
  // TODO: It now clears the entire cache. Might be too aggressive.
  Promise.all([removeSession(getCurrentSiteDomain()), cache.clear()])
    .then(() => {
      // TODO This should be separated from logout and instead be setup to listen on state changes on storage
      getRPCClient().userAuthenticated(false);
      clearCookies();
      // In addition to removing the regular cookies we also want to remove VSTOKEN2 on explicit logout
      removeCookie('VSTOKEN2');
    })
    .catch((reason) => {
      log.error('Failure while logging out', reason);
    });

export const getUser = (): Promise<Partial<UserAttributes>> => getCurrentUser();
